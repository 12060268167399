import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Preorder: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/preorder-header.jpg)' }}
        ></div>
        <article className="post">
          <h1>Preorder</h1>
          <p>
            We will introduce the Preorder function early October 2021 starting
            with Australia, Canada and UK for the Commonwealth Games
            Hydroconquest Special Edition.
          </p>
          <p>
            This feature will enable getting a downpayment at preorder placement
            for some selected new products and a payment of the remaining amount
            just before the delivery. This will enable to leverage the marketing
            effort at the beginning of the campaigns even if the products are
            not yet available.
          </p>
          <p>
            All the markets will participate to the testing mid September so
            they can use this feature as early as November.
          </p>

          <LightboxWrapper>
            <a href={'../../img/preorder checkout.jpg'}>
              <img
                src={'../../img/preorder checkout.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <LightboxWrapper>
            <a href={'../../img/preorder pDP.jpg'}>
              <img
                src={'../../img/preorder pDP.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <div className="spacer"></div>

          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/alexandre-fresco.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:alexandre.fresco@longines.com">
                    alexandre.fresco@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default Preorder

export const pageQuery = graphql`
  query Preorder_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
